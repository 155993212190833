import React from "react";

function ArrowBasic({width}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height="29"
    fill="none"
    version="1.1"
    viewBox="0 0 149 29"
  >
    <path
      fill="#f2f2f2"
      fillOpacity="1"
      stroke="#000"
      strokeDasharray="none"
      strokeOpacity="1"
      strokeWidth="1.5"
      d="M.89 14.57h144"
      imageRendering="auto"
      opacity="1"
    ></path>
    <path
      stroke="#000"
      strokeWidth="3"
      d="M133.39 2l12.5 12.5-12.5 12.5"
      opacity="1"
    ></path>
  </svg>
  );
}

export default ArrowBasic;
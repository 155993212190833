import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import MainContent from './pages/MainContent';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';

const linksArray = ["Курс", "Программа", "Цены  ", "Контакты"];

let theme = createTheme({
  typography: {
    fontFamily: [
      'Nunito',
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Header links={linksArray}/>
      <MainContent />
      <Footer />
    </div>
    </ThemeProvider>
  );
}

export default App;

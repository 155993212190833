import React from "react";

function NextArrow({fill}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    fill={fill}
    version="1.1"
    viewBox="0 0 330 330"
    xmlSpace="preserve"
  >
    <path d="M165 0C74.019 0 0 74.019 0 165s74.019 165 165 165 165-74.019 165-165S255.981 0 165 0zm60.606 175.605l-80 80.002C142.678 258.535 138.839 260 135 260s-7.678-1.464-10.606-4.394c-5.858-5.857-5.858-15.355 0-21.213l69.393-69.396-69.393-69.392c-5.858-5.857-5.858-15.355 0-21.213 5.857-5.858 15.355-5.858 21.213 0l80 79.998a14.996 14.996 0 01-.001 21.215z"></path>
  </svg>
  );
}

export default NextArrow;
import React from "react";

function InstagramFooter({fill}) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        fill={fill}
        viewBox="0 0 29 29"
      >
        <path         
          fillRule="evenodd"
          d="M8.366.086c-1.515.069-2.55.31-3.455.662A6.971 6.971 0 002.39 2.39 6.97 6.97 0 00.748 4.913C.396 5.82.155 6.855.086 8.371.016 9.89 0 10.375 0 14.243s.017 4.353.086 5.873c.069 1.515.31 2.55.662 3.456a6.97 6.97 0 001.642 2.523 6.977 6.977 0 002.52 1.643c.906.353 1.94.593 3.456.662 1.518.07 2.003.086 5.869.086 3.866 0 4.35-.016 5.869-.086 1.515-.069 2.55-.31 3.456-.662a6.983 6.983 0 002.52-1.643 6.982 6.982 0 001.642-2.523c.352-.905.593-1.94.662-3.456.07-1.52.085-2.005.085-5.873 0-3.868-.016-4.353-.085-5.872-.069-1.516-.31-2.551-.662-3.458a6.982 6.982 0 00-1.641-2.522A6.978 6.978 0 0023.56.748c-.906-.352-1.941-.593-3.456-.662C18.586.016 18.101 0 14.234 0c-3.865 0-4.35.017-5.868.086zm.117 25.75c-1.388-.063-2.142-.294-2.643-.49a4.419 4.419 0 01-1.637-1.065 4.415 4.415 0 01-1.065-1.637c-.195-.502-.427-1.256-.49-2.645-.069-1.502-.083-1.953-.083-5.756s.014-4.253.083-5.755c.063-1.389.295-2.143.49-2.645a4.415 4.415 0 011.065-1.638A4.404 4.404 0 015.84 3.14c.501-.195 1.255-.427 2.643-.49 1.5-.069 1.951-.083 5.752-.083 3.8 0 4.25.014 5.752.083 1.388.063 2.141.295 2.643.49a4.404 4.404 0 011.637 1.065c.498.499.806.973 1.065 1.638.195.502.427 1.256.49 2.645.068 1.502.082 1.952.082 5.755s-.014 4.254-.082 5.756c-.063 1.389-.295 2.143-.49 2.645a4.414 4.414 0 01-1.065 1.637 4.418 4.418 0 01-1.637 1.066c-.502.195-1.255.426-2.643.49-1.5.069-1.951.083-5.752.083-3.8 0-4.251-.015-5.752-.083zM20.125 6.64a1.709 1.709 0 103.418 0 1.709 1.709 0 00-3.418 0zm-13.2 7.604a7.312 7.312 0 007.31 7.313 7.311 7.311 0 007.31-7.313 7.312 7.312 0 00-7.31-7.314 7.312 7.312 0 00-7.31 7.314zm2.565 0a4.746 4.746 0 119.49 0 4.746 4.746 0 11-9.49 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

  export default InstagramFooter;
import React from "react";

function YoutubeFooter({fill}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      fill={fill}
      viewBox="0 0 28 20"
    >
      <path
        fillRule="evenodd"
        d="M18.363 8.829l-6.901-3.45s-1.917-.872-1.917.767v7.666s.035 1.17 1.917.383c1.309-.547 6.901-3.45 6.901-3.45s1.427-.914 0-1.916zm8.435 7.666c-.855 2.432-2.683 2.745-4.217 3.067-2.262.474-16.811.415-19.171-.384-1.472-.497-2.378-.711-3.068-4.216-.441-2.242-.47-8.84 0-11.115C.798 1.643 1.944.608 4.176.397c5.997-.569 16.02-.488 19.171 0 2.921.452 3.532 2.194 3.835 3.833.49 2.653.548 9.615-.384 12.265z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default YoutubeFooter;
import React from "react";

function FaceFooter({fill}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="26"
    fill={fill}
    viewBox="0 0 12 26"
  >
    <path
      fillRule="evenodd"
      d="M12 4.469H9.6c-1.653 0-1.6.639-1.6 1.807v1.849h4L11.2 13H8v13H2.4V13H0V8.125h2.4V5.829C2.4 2.174 4.196 0 8.44 0H12v4.469z"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export default FaceFooter;
import { Box, Container, Divider, Grid, Typography, Button } from "@mui/material";
import React from "react"
import Image from '../backgroundsBanners/BannerPriceBlock.jpg';


const PriceBlock = () => { 

    return(
        <Box textAlign="center" sx={{ backgroundImage: `url(${Image})`, backgroundSize: "cover"}} id="price">
            <Container maxWidth="lg"  sx={{ p: "46px 0px 58px 0px" }} >
            <Typography variant="h4" sx={{fontFamily:"Yeseva One", fontWeight:"600", textDecoration:"underline", textTransform:"uppercase", color:"white", mb:"9px"}}>
                цены курса
                </Typography>
                <Typography variant="h5" sx={{ m: "0 auto 15px auto", fontFamily:"Roboto", fontWeight:"400", color:"white", maxWidth:{xs:"300px", sm:"500px", md:"650px"    }}}>
                Лучший ученик на курсе получает <strong>-20% СКИДКУ</strong> на КУРС 
«Работа в Lightroom и Photoshop. Ретуш фотографий»
                </Typography>
                <Grid container spacing={2}>
                <Grid item xs={11} sm={8} md={7} lg={4} margin="auto">
                <Box sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "19px", p: "0" }} >
                    <Box sx={{p:"31px 30px 40px 30px"}}>
                    <Typography variant="h5" sx={{fontFamily:"Yeseva One", fontWeight:"600",  textTransform:"uppercase", mb:"10px"}}>
                «БАЗОВЫЙ»
                </Typography>
                <Box display="flex" alignItems={"center"} justifyContent="space-around">
                <Typography variant="h4"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"600", color:" #AF1E1E"}}>
                650 USD
                </Typography>
                <Typography variant="h5"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500", color:" #AF1E1E"}}>
                <s>750</s>
                </Typography>
                </Box>              
                <Typography variant="subtitle2" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"300"}}>
                при оплате до 1.04.23
                </Typography>
                <Divider sx={{ bgcolor: "#737373", width:{lg:'280px', md:"360px"} , m:"5px auto 15px auto" }}/>
                <Typography variant="h6" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                10 уроков:
                </Typography>
                <ul>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                Теория
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Практика: фотосъемка на улице, в помещении, в интерьерной студии
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Домашние задания
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Разбор работ
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Длительность обучения <u><strong>1 месяц</strong></u>
                </Typography>
                    </li>
                </ul>
                    </Box>                
                <Button sx={{ padding:"0px 32px", width:"100%", color: "#AF1E1E", backgroundColor:"white", borderRadius: "11px", border: "3px solid #AF1E1E", height: "61px", ':hover': {backgroundColor:"#AF1E1E",  color: "white" }, fontFamily:"Roboto", fontSize:"18px", lineHeight:"21px", fontWeight:"800"}} href="https://t.me/ifoto1"  target="_blank">
                  ЗАПИСАТЬСЯ на курс
                  </Button>
                </Box>
                </Grid>
                <Grid item xs={11} sm={8} md={7} lg={4} margin="auto">
                <Box sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "19px", p: "0" }} >
                    <Box sx={{p:"31px 30px 10px 30px"}}>
                    <Typography variant="h5" sx={{fontFamily:"Yeseva One", fontWeight:"600",  textTransform:"uppercase", mb:"10px"}}>
                    «VIP»
                </Typography>
                <Box display="flex" alignItems={"center"} justifyContent="space-around">
                <Typography variant="h4"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"600", color:" #AF1E1E"}}>
                850 USD
                </Typography>
                <Typography variant="h5"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500", color:" #AF1E1E"}}>
                <s>950</s>
                </Typography>
                </Box>          
                <Typography variant="subtitle2" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"300"}}>
                при оплате до 1.04.23
                </Typography>
                <Divider sx={{ bgcolor: "#737373", width:{lg:'280px', md:"360px"} , m:"5px auto 15px auto" }}/>
                <Typography variant="h6" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                14 уроков:
                </Typography>
                <ul>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Теория
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Практика: фотосъемка на улице, в помещении, в интерьерной студии и циклораме
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Домашние задания
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Разбор работ
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Урок «Как быстро наработать свою базу клиентов»
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Работа с упаковкой
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Урок по жанровым съемкам: «Личный опыт в мужских съемках, свадебных, женских, съемках беременности, newborn, семейных и детских».
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    МК «Фотосессия беременности в стиле минимализм (VOGUE)»
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Наставничество:
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    - создадим план развития Вашего фотобизнеса
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    - поработаем с блокировками и страхами
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    + 3 консультации по разбору новых работ
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Длительность обучения <u><strong>1,5 месяц</strong></u>
                </Typography>
                    </li>
                </ul>                
                    </Box>                
                <Button sx={{ padding:"0px 32px", width:"100%", color: "#AF1E1E", backgroundColor:"white", borderRadius: "11px", border: "3px solid #AF1E1E", height: "61px", ':hover': {backgroundColor:"#AF1E1E",  color: "white" }, fontFamily:"Roboto", fontSize:"18px", lineHeight:"21px", fontWeight:"800"}} href="https://t.me/ifoto1"  target="_blank">
                  ЗАПИСАТЬСЯ на курс
                  </Button>
                </Box>
                </Grid>
                <Grid item xs={11} sm={8} md={7} lg={4} margin="auto">
                <Box sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "19px", p: "0" }} >
                    <Box sx={{p:"31px 44px 40px 44px"}}>
                    <Typography variant="h5" sx={{fontFamily:"Yeseva One", fontWeight:"600",  textTransform:"uppercase", mb:"10px"}}>
                    «Профессиональный»
                </Typography>
                <Box display="flex" alignItems={"center"} justifyContent="space-around">
                <Typography variant="h4"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"600", color:" #AF1E1E"}}>
                750 USD
                </Typography>
                <Typography variant="h5"  textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500", color:" #AF1E1E"}}>
                <s>850</s>
                </Typography>
                </Box>          
                <Typography variant="subtitle2" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"300"}}>
                при оплате до 1.04.23
                </Typography>
                <Divider sx={{ bgcolor: "#737373", width:{lg:'280px', md:"360px"} , m:"5px auto 15px auto" }}/>
                <Typography variant="h6" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                12 уроков:
                </Typography>
                <ul>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Теория
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Практика: фотосъемка на улице, в помещении, в интерьерной студии и циклораме
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Домашние задания
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Разбор работ
                </Typography>
                    </li>
                    <li>
                    <Typography variant="subtitle1" textAlign="start" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                    Длительность обучения <u><strong>1,5 месяц</strong></u>
                </Typography>
                    </li>
                </ul>        
                    </Box>                
                <Button sx={{ padding:"0px 32px", width:"100%", color: "#AF1E1E", backgroundColor:"white", borderRadius: "11px", border: "3px solid #AF1E1E", height: "61px", ':hover': {backgroundColor:"#AF1E1E",  color: "white" }, fontFamily:"Roboto", fontSize:"18px", lineHeight:"21px", fontWeight:"800"}} href="https://t.me/ifoto1"  target="_blank">
                  ЗАПИСАТЬСЯ на курс
                  </Button>
                </Box>
                </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default PriceBlock;
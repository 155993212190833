import { Box, Container, Divider, Grid, Typography, Button } from "@mui/material";
import React from "react"
import Countdown from "react-countdown";
import Image from '../backgroundsBanners/BannerFirstBlock.jpg';


const EnterBlock = () => { 

    const str = "2023-04-01";

    const date = new Date(str);

    const Completionist = () => <span>You are good to go!</span>;   
    const renderer = ({days, hours, minutes, completed }) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <div>
            <Typography variant="h3" sx={{fontFamily:"Roboto", fontWeight:"700",color:"#AF1E1E"}}>{days} : {hours} : {minutes}</Typography>
            <Box display="flex" justifyContent="space-between" sx={{maxWidth:{xs:"180px", sm:"243px"}, m:"auto"}}>
            <Typography variant="h6" sx={{fontFamily:"Roboto", fontWeight:"400"}}>дней</Typography>
            <Typography variant="h6" sx={{fontFamily:"Roboto", fontWeight:"400"}}>часов</Typography>
            <Typography variant="h6" sx={{fontFamily:"Roboto", fontWeight:"400"}}>минут</Typography>
            </Box>          
            </div>;
        }
      };

    return(
        <Box sx={{ backgroundImage: `url(${Image})`, backgroundSize:"cover"}} id="course">
            <Container maxWidth="xl" sx={{p:"40px 0px"}}>
            <Grid container direction="row" justifyContent="center" >
                <Grid item textAlign="center" alignItems="center" xs={12} md={5}>
                    <Typography variant="h4" sx={{fontFamily:"Inter", fontWeight:"600"}}>
                    КУРС ПО ФОТОГРАФИИ 
                    </Typography>
                    <Divider sx={{ bgcolor: "#737373", width: '280px', m:"auto" }}/>
                    <Typography variant="h4" color="#AF1E1E" sx={{fontFamily:"Inter", fontWeight:"600"}}>
                    “ОТ НОВИЧКА ДО ПРОФИ”
                    </Typography>
                <Box sx={{m:"15px auto 28px auto",  maxWidth:{xs:"300px", sm:"500px"}, boxShadow:"0px 7px 24px rgba(0, 0, 0, 0.15)", borderRadius:"10px", p:"17px 10px"}}> 
                    <Typography variant="h6" sx={{fontFamily:"Roboto", fontWeight:"400"}}>
                        Меня зовут Ирина Гапула, я профессиональный фотограф 
                        с опытом более 10 лет, создатель фотошколы.
                        И я помогу тебе стать 
                    </Typography>
                    <Typography  variant="h6" sx={{fontFamily:"Roboto", fontWeight:"600"}}>
                        ПРОФЕССИОНАЛЬНЫМ ФОТОГРАФОМ!
                    </Typography>
                </Box>
                <Typography variant="h5" sx={{fontFamily:"Roboto", fontWeight:"400", textTransform:"uppercase"}}>
                    успей записаться до <strong>1 апреля</strong> 
                </Typography>
                <Typography marginBottom="15px" variant="h5" sx={{fontFamily:"Roboto", fontWeight:"400"}}>
                получи скидку
                </Typography>    
                <Countdown date={date}  renderer={renderer}/>  
                <Button variant="h5" sx={{  mt: "16px", mr: "9px", color: "white", backgroundColor:"#AF1E1E", borderRadius: "11px", height: "50px", width:"80px", ':hover': {border: "1px solid #AF1E1E",  color: "#AF1E1E"}, fontFamily:"Roboto", fontSize:"14px", lineHeight:"16px", fontWeight:"700" }} >
                  Старт 11.04
                  </Button>  
                <Button sx={{ mt: "16px", color: "white", backgroundColor:"#AF1E1E", borderRadius: "11px", height: "50px", ':hover': {border: "1px solid #AF1E1E",  color: "#AF1E1E" }, fontFamily:"Roboto", fontSize:"20px", lineHeight:"23px", fontWeight:"700"}} href="https://t.me/ifoto1"  target="_blank">
                  ЗАПИСАТЬСЯ на курс
                  </Button>     
                </Grid>
                <Grid item xs={12} md={7} margin="auto" alignItems={"center"}>
                <Box width={{xs:"320px", sm:"520px", lg:"700px"}} margin="auto" >
                <img src="images/IrinaPhotoFirstBlock.png" width="100%" alt="Irina" />
                </Box>
                </Grid>
            </Grid>     
            </Container>
        </Box>
    );

}

export default EnterBlock;

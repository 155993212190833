import { Box, Container, Divider, Grid, Typography, Button } from "@mui/material";
import React from "react"
import Image from '../backgroundsBanners/BannerProgrammBlock.jpg';


const ProgrammBlock = () => { 

    return(
        <Box textAlign="center" sx={{ backgroundImage: `url(${Image})`, backgroundSize: "cover" }} id="programm">
            <Container maxWidth="xl" sx={{ p: "57px 24px 77px 24px" }}>
                <Typography variant="h4" sx={{fontFamily:"Yeseva One", fontWeight:"600", textDecoration:"underline", textTransform:"uppercase", color: "#FFFFFF", mb:"9px"}}>
                    программа курса
                </Typography>
                <Typography variant="h5" sx={{ m: "0 auto 25px auto", fontFamily:"Roboto", fontWeight:"400", color: "#FFFFFF" }} maxWidth={{md:"750px", sm:"500px", xs:"300px"}}>
                    После прохождения курса у тебя будет сертификат и готовое портфолио,
                    и ты сразу сможешь работать!
                </Typography>
                <Grid container direction="row" justifyContent="center" spacing={3}>
                    <Grid item xl={6} xs={12} md={8} sm={12}>
                        <Box sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "10px", p: "19px 41px 19px 30px" }}>
                            <Typography variant="h6" sx={{ m: "0 auto 15px auto",  fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}} maxWidth="400px">
                                КУРС «БАЗОВЫЙ» <br/>10 уроков в ПРОФЕССИОНАЛЬНОМ и VIP
                                </Typography>
                            <Grid  container direction="row" textAlign="start">
                                <Grid item xs={12} sm={5}>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>1 УРОК</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Фотоаппараты и объективы<br/>
                                        Техника безопасности<br/>
                                        Фокусное расстояние<br/>
                                        Ошибки при создании фото</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>2 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Основные настройки камеры:<br/>
                                        Экспозиция<br/>
                                        ISO<br/>
                                        Выдержка<br/>
                                        Диафрагма<br/>
                                        Покадровая съемка</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>3 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Баланс белого<br/>
                                        Идеальный скинтон<br/>
                                        Формат фотографий<br/>
                                        Кадрирование<br/>
                                        Фон<br/>
                                        Фокусировка<br/>
                                        Правила съемки</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>4 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                                        Композиция<br/>
                                        Перспектива<br/>
                                        Секрет «вкусного исходника»<br/>
                                        Сюжет в фотографии<br/>
                                        Как правильно подготовиться к съемке<br/>
                                        Фокусировка</Typography>
                                </Grid >
                                <Grid item  xs={0} sm={2}>
                                <Divider orientation="vertical" sx={{ bgcolor: "#888888", width: '1px', m:"auto", display:{xs:"none", sm:"inherit"}}}/>
                                </Grid>                             
                                <Grid item xs={12} sm={5}>
                                <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>5 УРОК</Typography>
                                <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Учимся снимать осознанно <br/>
                                        Разноплановость <br/>
                                        Позирование
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>6 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Освещение<br/>
                                        Учимся видеть свет и снимать на улице</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>7 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Учимся снимать со светом от окна</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>8 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Акценты на фотографии<br/>
                                        Колористика</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>9 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Эмоции<br/>
                                        Динамика в фотографии<br/>
                                        Съемка с животными</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>10 УРОК</Typography>
                                        <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                        Студийное оборудование интерьерной студии<br/>
                                        Работа с накамерной вспышкой<br/>
                                        Съемка в интерьерной студии с импульсным светом</Typography>
                                </Grid>                       
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} md={8} xl={5} spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Box textAlign="start" sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "10px", p:{xs:"19px 15px 30px 15px", lg:"19px 30px 30px 30px" } }}>
                                <Typography textAlign="center" variant="h6" sx={{ m: "0 auto 15px auto",  fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>Для курса
                                    «ПРОФЕССИОНАЛЬНЫЙ»</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>11 УРОК</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                    Студийное оборудование в циклораме<br/> 
                                    Разбор световых схем Настройки камеры</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>12 УРОК</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                                    Съемка в циклораме<br/> Создаём красивый свето-теневой рисунок <br/>
                                    Основы съемки для интернет магазинов <br/>
                                    Особенности работы с клиентами +size и 40+ <br/>
                                    Работа с фильтрами <br/>
                                    Работа с фонами</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box textAlign="start" sx={{ backgroundColor: "white", border: "2px solid #AF1E1E", borderRadius: "10px", p: "19px 30px 30px 30px" }}>
                            <Typography textAlign="center" variant="h6" sx={{ m: "0 auto 15px auto",  fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>Для курса
                                    «VIP»</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>13 УРОК</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", mb:"15px"}}>
                                    Урок «Как быстро наработать свою базу клиентов»<br/>
                                    Работа с упаковкой<br/>
                                    Урок по жанровым съемкам: «Личный опыт в мужских съемках, свадебных, женских, съемках беременности, newborn, семейных и детских».</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500", color: "#AF1E1E"}}>14 УРОК</Typography>
                                    <Typography variant="subtitle1" sx={{fontFamily:"Roboto", fontWeight:"500"}}>
                                    МК «Фотосессия беременности в стиле минимализм (VOGUE)»</Typography>                       
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{mt:"auto"}}>
                            <Box sx={{ backgroundColor: "#AF1E1E", borderRadius: "10px", p: "16px 19px" }}>
                            <Box sx={{background:"linear-gradient(180deg, #989898 0%, #CDCDCD 100%)", borderRadius:"10px"}} display="flex" justifyContent="center" position="relative">
                            <Box > <img src="images/IrinaPhotoVideo.png" height="192px" alt="Irina" /></Box>                           
                            <Box width={{xs:"100px" , sm:"119px"}} position={{xs:"absolute", sm:"inherit"}} right="0px" top="0px">
                            <Button href="https://www.youtube.com/watch?v=h8idCjgDwKc&t=5s&ab_channel=%D0%A4%D0%BE%D1%82%D0%BE%D0%9A%D1%83%D1%80%D1%81"  target="_blank" >
                            <img src="images/VideoButtonImg.svg" alt="VideoIcon" width="100%"></img>                                                   
                            </Button>
                            <Typography variant="h6"> Про курс 
                            </Typography>  
                            </Box>
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

}

export default ProgrammBlock;

import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react"
import Image from '../backgroundsBanners/BannerHowStudyGoingBlock.jpg';
import ArrowBasic from "../components/slider/ArrowBasic";


const HowStudyGoingBlock = () => { 

    const theme = useTheme();
    const isMatchXl = useMediaQuery(theme.breakpoints.up("xl"));//4
    const isMatchMd = useMediaQuery(theme.breakpoints.up("md"));//3
    const isMatchSm = useMediaQuery(theme.breakpoints.up("sm"));//2
    const isMatchXs = useMediaQuery(theme.breakpoints.up("xs"));//1

    let widthArrow = "149"

    if(isMatchMd) widthArrow = "149"; 
    else if(isMatchSm) widthArrow = "100"; 
    else if(isMatchXs) widthArrow = "50"; 


    return(
        <Box textAlign="center" sx={{ backgroundImage: `url(${Image})`, backgroundSize: "cover" }}>
            <Container maxWidth="xl" sx={{ p: "53px 0px 37px 0px" }}>
            <Typography variant="h4" sx={{fontFamily:"Yeseva One", fontWeight:"600", textDecoration:"underline", textTransform:"uppercase", mb:"65px"}}>
                Как проходит обучение
                </Typography>
                <Grid container direction="row" justifyContent="center" >
                    <Grid item xl={1.5} xs={4} marginBottom="50px">
                    <span className="circle" >1</span>
                    <Typography variant="h5" sx={{ fontFamily:"Roboto", fontWeight:"700", mt:"32px"}}>
                    Обучение в группе
                </Typography>
                <Typography variant="h6" sx={{ fontFamily:"Roboto", fontWeight:"400", mt:"10px"}}>
                Онлайн встречи, 
                уроки в записи
                </Typography>
                    </Grid >
                    <Grid item xl={1.5} xs={2}>               
                    <ArrowBasic width={widthArrow}/>   
                    </Grid>                    
                    <Grid item xl={1.5} xs={4} marginBottom="50px">
                    <span className="circle" >2</span>
                    <Typography variant="h5" sx={{ fontFamily:"Roboto", fontWeight:"700", mt:"32px"}}>
                    Материалы курса
                </Typography>
                <Typography variant="h6" sx={{ fontFamily:"Roboto", fontWeight:"400", mt:"10px"}}>
                Практика съемки, примеры работ, презентация
                </Typography>
                    </Grid>     
                    <Grid item xl={1.5} xs={2} sx={{display:{xl:"inherit", xs:"none"}}}>                
                    <ArrowBasic />   
                    </Grid>                  
                    <Grid item xl={1.5} xs={4} marginBottom="50px">
                    <span className="circle" >3</span>
                    <Typography variant="h5" sx={{ fontFamily:"Roboto", fontWeight:"700", mt:"32px"}}>
                    Ход обучения
                </Typography>
                <Typography variant="h6" sx={{ fontFamily:"Roboto", fontWeight:"400", mt:"10px"}}>
                Кураторство, домашние задания, обратная связь
                </Typography>
                    </Grid>    
                    <Grid item xl={1.5} xs={2}>               
                    <ArrowBasic width={widthArrow}/>   
                    </Grid>                       
                    <Grid item xl={1.5} xs={4} marginBottom="50px">
                    <span className="circle" >4</span>
                    <Typography variant="h5" sx={{ fontFamily:"Roboto", fontWeight:"700", mt:"32px"}}>
                    Результат
                </Typography>
                <Typography variant="h6" sx={{ fontFamily:"Roboto", fontWeight:"400", mt:"10px"}}>
                Портфолио из нескольких
работ + сертификат
                </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

}

export default HowStudyGoingBlock;

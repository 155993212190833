import React from "react"
import EnterBlock from "./EnterBlock";
import GaleryBlock from "./GaleryBlock";
import HowStudyGoingBlock from "./HowStudyGoingBlock";
import PriceBlock from "./PriceBlock";
import ProgrammBlock from "./ProgrammBlock";

const MainContent = () => { 

    return(
        <div>
            <EnterBlock />
            <ProgrammBlock />
            <GaleryBlock />
            <PriceBlock />
            <HowStudyGoingBlock />
        </div>
    );

}

export default MainContent;

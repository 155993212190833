import React, {useState} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrevArrow from "./slider/PrevArrow";
import { Button, styled, useMediaQuery, useTheme} from "@mui/material";
import NextArrow from "./slider/NextArrow";


const StyledButton = styled(Button)`
  &:hover {
    background: none;
  }
`

function SampleNextArrow(props) {

  const [isHoverNextArrow, setIsHoverNextArrow] = useState(false);

  const { className, style, onClick } = props;
  return (
    <div 
    style={{position:"absolute", right:"-40px", top:"40%"}}   
    onClick={onClick}>
    <StyledButton  disableRipple disableFocusRipple onMouseEnter={() => setIsHoverNextArrow(true)} onMouseLeave={() => setIsHoverNextArrow(false)}  >
    <NextArrow fill= {isHoverNextArrow ? "#AF1E1E" : "black"} stroke="#F3F6F9"/>
  </StyledButton>
  </div>
  );
}

function SamplePrevArrow(props) {

  const [isHoverPrevArrow, setIsHoverPrevArrow] = useState(false);

  const { className, style, onClick } = props;
  return (
    <div 
    style={{position:"absolute", left:"-38px", top:"40%", zIndex:1200}}   
    onClick={onClick}>
    <StyledButton disableRipple disableFocusRipple onMouseEnter={() => setIsHoverPrevArrow(true)} onMouseLeave={() => setIsHoverPrevArrow(false)} >
    <PrevArrow fill= {isHoverPrevArrow ? "#AF1E1E" : "black"} stroke="#F3F6F9"/>
  </StyledButton>
  </div>
  );
}


export default function BaseSlider() {
  const theme = useTheme();
  const isMatchXl = useMediaQuery(theme.breakpoints.up("xl"));//4
  const isMatchLg = useMediaQuery(theme.breakpoints.up("lg"));//3
  const isMatchSm = useMediaQuery(theme.breakpoints.up("sm"));//2
  const isMatchXs = useMediaQuery(theme.breakpoints.up("xs"));//1

    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />    
    };

    if(isMatchXl) settings.slidesToShow = 4; 
    else if(isMatchLg) settings.slidesToShow = 3; 
    else if(isMatchSm) settings.slidesToShow = 2; 
    else if(isMatchXs) settings.slidesToShow = 1; 

    const images = []

    for(let i = 0; i < 15; i++) images.push(<img className="sliderImg" key={i} src={`images/sliderImg/SliderImg${i+1}.png`} alt="ExamplePhoto"/>  )


    return (
      <Slider {...settings}>
        {images}
      </Slider>
    );
  }

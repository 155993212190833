import React from "react";

function InstagramLogo({fill}) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="29"
        fill={fill}
        viewBox="0 0 28 29"
      >
        <path
          fillRule="evenodd"
          d="M8.228.085C6.738.152 5.72.389 4.83.735a6.856 6.856 0 00-2.48 1.616A6.855 6.855 0 00.735 4.832c-.346.892-.583 1.91-.65 3.4C.016 9.728 0 10.205 0 14.009c0 3.805.016 4.281.085 5.776.067 1.49.304 2.509.65 3.4a6.855 6.855 0 001.615 2.481 6.86 6.86 0 002.48 1.616c.89.347 1.908.583 3.398.65 1.493.07 1.97.085 5.772.085 3.803 0 4.28-.016 5.773-.084 1.49-.068 2.507-.304 3.398-.651a6.866 6.866 0 002.48-1.616 6.867 6.867 0 001.614-2.481c.346-.891.583-1.91.651-3.4.068-1.495.084-1.971.084-5.776 0-3.804-.016-4.281-.084-5.775-.068-1.491-.305-2.51-.65-3.4A6.867 6.867 0 0025.65 2.35 6.862 6.862 0 0023.17.735c-.89-.346-1.908-.583-3.398-.65C18.279.017 17.803 0 14 0c-3.802 0-4.279.017-5.772.085zm.115 25.326c-1.365-.063-2.106-.29-2.6-.482a4.345 4.345 0 01-1.61-1.048 4.34 4.34 0 01-1.047-1.61c-.192-.494-.42-1.236-.482-2.602-.067-1.477-.082-1.92-.082-5.66 0-3.741.015-4.184.082-5.661.063-1.366.29-2.108.482-2.601a4.342 4.342 0 011.048-1.61c.49-.491.956-.795 1.61-1.049.493-.191 1.234-.42 2.599-.482 1.476-.067 1.92-.082 5.657-.082 3.738 0 4.18.015 5.658.082 1.364.062 2.106.29 2.6.482a4.33 4.33 0 011.609 1.048c.49.49.793.957 1.047 1.61.192.494.42 1.236.482 2.602.068 1.477.081 1.92.081 5.66s-.013 4.184-.08 5.661c-.063 1.366-.291 2.108-.483 2.601a4.34 4.34 0 01-1.047 1.61c-.49.49-.956.794-1.61 1.049-.493.192-1.235.42-2.6.482-1.476.067-1.918.081-5.657.081-3.738 0-4.18-.014-5.657-.081zm11.45-18.88a1.68 1.68 0 103.362 0 1.68 1.68 0 00-3.361 0zM6.812 14.008a7.191 7.191 0 007.19 7.193c3.97 0 7.189-3.22 7.189-7.193A7.191 7.191 0 0014 6.815c-3.97 0-7.189 3.22-7.189 7.194zm2.523 0a4.668 4.668 0 119.333 0 4.668 4.668 0 11-9.333 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }

  export default InstagramLogo;
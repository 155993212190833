import React, { useState} from 'react';
import { Box, Container, Grid, Typography, styled, Link, Button, Stack } from "@mui/material";
import TelegramFooter from './social/TelegramFooter';
import InstagramFooter from './social/InstagramFooter';
import YoutubeFooter from './social/YoutubeFooter';
import FaceFooter from './social/FaceFooter';


const contacts = ["+38 (066) 360-02-29", " +38 (093) 197-47-42", "dp200887gio@gmail.com"];
const contactsImg = ["images/mobile.svg", "images/mobile.svg", "images/email.svg"]

const FooterContainer = styled(Box)(() => ({
  width: "100%",
  height: "auto",
  backgroundColor: "#202022",
  paddingTop: "1rem",
  paddingBottom: "0",
  marginTop: "auto",
}));



const Footer = () => {

  const [isHoverTelegram, setIsHoverTelegram] = useState(false);
const [isHoverInstagram, setIsHoverInstagram] = useState(false);
const [isHoverYouTube, setIsHoverYouTube] = useState(false);
const [isHoverFacebook, setIsHoverFacebook] = useState(false);
    return (
      <FooterContainer id="contacts">
        <Container maxWidth="lg" sx={{p:"10px 0px"}}>
       
          <Grid container direction="row" justifyContent="space-around">
          <Grid item xs={12} display={{xs: "inherit", md: "none"}}>
            <Typography margin="auto" width={{xs:"160px", sm:"200px", md:"240px"}} textAlign="center" >
                <img src='images/LogoFooter.svg' width="100%"></img>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Box width={{xs:"180px", sm:"200px", md:"280px"}} margin="auto">
              <img src="images/IrinaPhoto.png" alt="Irina" width="100%"/>
              </Box>
            </Grid>         
            <Grid display={{xs: "none", md: "inherit"}}>
            <Typography >
                <img src='images/LogoFooter.svg'></img>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >           
            <Typography variant="h5" textAlign="center" sx={{ fontFamily:"Roboto", fontWeight:"700",  color:"white"}}>
              Контакты
              </Typography>
              {contacts.map((contact, index) => (
                     <Box key={index} display="flex" sx={{mt:"12px"}} justifyContent="center"> 
                     <img src={contactsImg[index]}></img>
                     <Typography color="white" variant="h5" sx={{ml:"10px"}}>
                     {contact}
                     </Typography>
                     </Box>  
                  ))}
                <Stack direction="row" spacing={{xs:1, sm:2}} sx={{mt:"17px"}} justifyContent="center">
                   <Button  onMouseEnter={() => setIsHoverTelegram(true)} onMouseLeave={() => setIsHoverTelegram(false)} href="https://t.me/ifoto1"  target="_blank" variant="contained"  sx={{bgcolor:"#AF1E1E",  width: {xs: 42, md: 58}, height: {xs: 40, md: 54}, ':hover': {bgcolor: "white" }}}>
                          <TelegramFooter fill= {isHoverTelegram ? "#AF1E1E" : "white"}/>
                        </Button>
                        <Button  onMouseEnter={() => setIsHoverInstagram(true)} onMouseLeave={() => setIsHoverInstagram(false)} href="https://www.instagram.com/irinagapula.photo/"  target="_blank"  sx={{bgcolor:"#AF1E1E",  width: {xs: 42, md: 58}, height: {xs: 40, md: 54}, ':hover': {bgcolor: "white" }}}>
                          <InstagramFooter fill= {isHoverInstagram ? "#AF1E1E" : "white"}/>
                        </Button>
                        <Button  onMouseEnter={() => setIsHoverYouTube(true)} onMouseLeave={() => setIsHoverYouTube(false)} href="https://www.youtube.com/channel/UCbAHk_KZjibiBfh_dbW7C_w"  target="_blank"  sx={{bgcolor:"#AF1E1E",  width: {xs: 42, md: 58}, height: {xs: 40, md: 54}, ':hover': {bgcolor: "white" }}}>
                          <YoutubeFooter fill= {isHoverYouTube ? "#AF1E1E" : "white"}/>
                        </Button>
                        <Button  onMouseEnter={() => setIsHoverFacebook(true)} onMouseLeave={() => setIsHoverFacebook(false)} href="https://www.facebook.com/irinagapula.photo/"  target="_blank"  sx={{bgcolor:"#AF1E1E",  width: {xs: 42, md: 58}, height: {xs: 40, md: 54}, ':hover': {bgcolor: "white" }}}>
                          <FaceFooter fill= {isHoverFacebook ? "#AF1E1E" : "white"}/>
                        </Button>
                        </Stack>
            </Grid>
          </Grid>
        </Container>
        <Box display="flex" justifyContent="space-around" sx={{bgcolor:"black", p:"24px 15px"}}>
          <Link href="#" underline="none">
            <Typography  color="white" variant="h6" sx={{opacity:"0.5"}}>Політика конфіденційності</Typography>       
          </Link>
          <Link href="#" underline="none" display={{xs: "none", md: "inherit"}}>        
          <Typography  color="white" variant="h6" sx={{opacity:"0.5"}}>Згода на обробку персональних даних</Typography>       
          </Link>
          <Link href="#" underline="none" display={{xs: "none", sm: "inherit"}}>
          <Typography  color="white" variant="h6" sx={{opacity:"0.5"}}> Договір оферти</Typography>               
          </Link>
        </Box>
      </FooterContainer>
    );
}

export default Footer;

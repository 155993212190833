import React from "react";

function TelegramLogo({fill}) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="24"
        fill={fill}
        viewBox="0 0 28 24"
      >
        <path
  
          d="M27.817.982C27.535.346 26.935 0 26.144 0c-.573 0-1.082.182-1.146.21-.164.063-16.906 6.456-23.772 9.275-.6.246-.992.591-1.155 1.037-.255.682.227 1.382.682 1.582 1.71.755 5.256 1.792 6.366 2.11.363 1.219 1.646 5.475 2.246 7.294.155.473.618.755 1.228.755.09 0 .145-.01.145-.01.164-.018.31-.1.41-.218a.598.598 0 00.154-.363l.355-5.139c.673.5 1.646 1.237 2.728 2.047a582.69 582.69 0 006.102 4.52c.51.363.992.545 1.455.545 1.028 0 1.62-.882 1.82-1.637 1.181-4.592 2.91-13.268 3.946-18.452l.228-1.137c.145-.718.018-1.128-.119-1.437zM10.474 15.287l-.318 4.557a647.703 647.703 0 01-1.928-6.33c0-.009-.018-.018-.018-.036v-.028l13.087-8.52s.89-.61 1.227-.683c.455-.1.564.082.482.191-.318.427-1.819 1.719-1.819 1.719l-10.43 8.64v.008a.666.666 0 00-.282.482z"
        ></path>
      </svg>
        
    );
  }

  export default TelegramLogo;
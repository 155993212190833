import React, { useState } from 'react';
import { AppBar, Button, Grid, Tab, Tabs, Toolbar, Typography, useMediaQuery, useTheme, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Box } from '@mui/system';
import DrawerComp from './DrawerComp';
import TelegramLogo from './social/TelegramLogo';
import InstagramLogo from './social/InstagramLogo';

const Header = ({links}) => {

  const hrefs = ["#course", "#programm", "#price", "#contacts"];

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [tabNumber, setTabNumber] = useState(0);
  
  const [isHoverTelegram, setIsHoverTelegram] = useState(false);
  const [isHoverInstagram, setIsHoverInstagram] = useState(false);

    return (
      <AppBar position='relative'>
        <Toolbar sx={{backgroundColor: "white" }}>
          {isMatch ? <>
            <Typography>
              <HomeIcon />
            </Typography>
            <DrawerComp links={links} />
          </> :
            <Grid alignItems={"center"} justifyContent={{xl: "center", sm: "space-evenly"}} container>
              <Grid item xs={2}>
                <img src='images/Logo.svg'></img>
              </Grid>
              <Grid item md={6} lg={4} xl={3} container justifyContent="center">
                <Tabs value={false}              
                  TabIndicatorProps={{
                   hidden: true                
                  }}                
                >
                  {links.map((link, index) => (
                    <Tab  key={index} label={link} sx={{fontFamily:"Roboto", fontSize:"16px", lineHeight:"19px", fontWeight:"400", p: "0", ':hover': {color: "red"}}} href={hrefs[index]}/>
                  ))}
                </Tabs>
              </Grid>
           
              <Grid item xs={3}  container justifyContent="center" sx={{display:{lg:"inherit", md:"none"}}}>
                <Box display="flex" alignItems="center">
                <Button  onMouseEnter={() => setIsHoverTelegram(true)} onMouseLeave={() => setIsHoverTelegram(false)} href="https://t.me/ifoto1"  className="social" target="_blank"  sx={{display:{xl:"inherit", xs:"none"}}}>    
                <TelegramLogo fill={isHoverTelegram ? "#AF1E1E" : "black"}/>
                      </Button>
                        <Button  onMouseEnter={() => setIsHoverInstagram(true)} onMouseLeave={() => setIsHoverInstagram(false)} href="https://www.instagram.com/irinagapula.photo/"  target="_blank" sx={{display:{xl:"inherit", xs:"none"}}} >
                          <InstagramLogo fill= {isHoverInstagram ? "#AF1E1E" : "black"}/>
                        </Button>
                  <Button sx={{ padding:"0px 24px", ml:"10px", color: "#AF1E1E", backgroundColor:"white", borderRadius: "11px", border: "1px solid #AF1E1E", height: "50px", ':hover': {backgroundColor:"#AF1E1E",  color: "white" }, fontFamily:"Roboto", fontSize:"16px", lineHeight:"19px", fontWeight:"700"}} href="https://t.me/ifoto1"  target="_blank">
                  ЗАПИСАТЬСЯ на курс
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }

        </Toolbar>
      </AppBar>
    );
}

export default Header;

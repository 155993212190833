import { Box, Container, Divider, Grid, Typography, Button } from "@mui/material";
import React from "react"
import Image from '../backgroundsBanners/BannerGaleryBlock.jpg';
import BaseSlider from "../components/BaseSlider";

const GaleryBlock = () => { 

    return(
        <Box textAlign="center" sx={{ backgroundImage: `url(${Image})`, backgroundSize: "cover"}}>
            <Container maxWidth="xl" sx={{ p: {sm:"29px 0px 80px 0px", xs:"29px 0px 40px 0px"} }} >
            <Typography variant="h4" sx={{fontFamily:"Yeseva One", fontWeight:"600", textDecoration:"underline", textTransform:"uppercase", mb:"9px"}}>
            Галерея работ
            </Typography>
            <Typography variant="h5" sx={{ m: "0 auto 30px auto", fontFamily:"Roboto", fontWeight:"400", maxWidth:{xs:"300px", sm:"750px"}}}>
            Я фотограф с 10 летним опытом, помогу тебе стать ПРОФИ!
            </Typography>
            <Box paddingLeft="45px" paddingRight="45px" sx={{maxWidth:{xs:"340px", sm:"680px", lg:"1020px", xl:"1360px"}}}  margin="auto"> 
                <BaseSlider />        
                </Box>                  
            </Container>
        </Box>
    );

}

export default GaleryBlock;
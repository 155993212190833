import React from "react";

function TelegramFooter({fill}) {
  return (
    <svg
      width="26"
      height="22"
      fill={fill}
      viewBox="0 0 26 22"
    >
      <path
        d="M25.83.912C25.57.321 25.011 0 24.277 0c-.533 0-1.005.169-1.065.194C23.06.254 7.514 6.19 1.138 8.808c-.557.228-.92.549-1.072.963-.237.633.21 1.283.633 1.469 1.588.7 4.881 1.663 5.911 1.959.338 1.132 1.529 5.084 2.086 6.773.144.439.574.7 1.14.7.085 0 .135-.008.135-.008a.584.584 0 00.38-.203.556.556 0 00.144-.337l.33-4.772c.624.465 1.528 1.149 2.533 1.9a541.296 541.296 0 005.666 4.198c.473.337.92.506 1.351.506.954 0 1.503-.819 1.689-1.52 1.098-4.264 2.702-12.32 3.665-17.134l.211-1.056c.135-.667.017-1.047-.11-1.334zM9.726 14.195l-.295 4.231a602.718 602.718 0 01-1.79-5.877c0-.009-.017-.017-.017-.034v-.025l12.152-7.913s.827-.566 1.14-.633c.422-.093.523.076.447.177-.296.397-1.689 1.596-1.689 1.596L9.988 13.74v.008a.618.618 0 00-.262.447z"
      ></path>
    </svg>
  );
}

export default TelegramFooter;
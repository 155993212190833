import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItem, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const DrawerComp = ({links}) => {

    const [open, setOpen] = useState(false);
    const hrefs = ["#course", "#programm", "#price", "#contacts"];
    return (
        <>
            <Drawer PaperProps={{
                sx: { backgroundColor: "white" }
            }} open={open} onClose={() => setOpen(false)}>
                <List>
                    {links.map((link, index) => (
                        <ListItemButton onClick={() => setOpen(false)} key={index} divider>
                            <ListItemIcon>
                            <Typography variant="h6" >
                                <ListItem component="a" sx={{ color: "black" }} href={hrefs[index]}>                                   
                                    {link}
                                    </ListItem >
                                    </Typography>
                            </ListItemIcon>
                        </ListItemButton>
                    ))}
                </List>
            </Drawer>
            <Box width={{xs:"150px",sm:"180px"}}><img src='images/Logo.svg' width="100%"></img></Box>
            
            <IconButton  sx={{ color: "black", marginLeft: "auto" }} onClick={() => setOpen(!open)}>
                <MenuRoundedIcon/>
            </IconButton>
        </>
    );
}

export default DrawerComp;
